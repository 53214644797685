// Basic
$rqb-spacing: 0.5rem !default;
$rqb-background-color: rgba(44, 174, 191, 0.035) !default;
$rqb-border-color: rgba(143, 143, 148, 0.231) !default;
$rqb-border-style: solid !default;
$rqb-border-radius: 4px !default;
$rqb-border-width: 1px !default;

// Drag-and-drop
$rqb-dnd-hover-border-bottom-color: #2baebf6c !default;
$rqb-dnd-hover-copy-border-bottom-color: #669933 !default;
$rqb-dnd-hover-border-bottom-style: dashed !default;
$rqb-dnd-hover-border-bottom-width: 2px !default;

// Branches
$rqb-branch-indent: $rqb-spacing !default;
$rqb-branch-color: $rqb-border-color !default;
$rqb-branch-width: $rqb-border-width !default;
$rqb-branch-radius: $rqb-border-radius !default;
$rqb-branch-style: $rqb-border-style !default;

// Default styles
.ruleGroup {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: $rqb-spacing;
  padding: $rqb-spacing;
  border-color: $rqb-border-color;
  border-style: $rqb-border-style;
  border-radius: $rqb-border-radius;
  border-width: $rqb-border-width;
  background: $rqb-background-color;
  .ruleGroup-addRule {
    padding: 2px;
  }
  .ruleGroup-body {
    display: flex;
    flex-direction: column;
    gap: $rqb-spacing;

    &:empty {
      display: none;
    }
  }

  .ruleGroup-header,
  .rule {
    display: flex;
    gap: $rqb-spacing;
    align-items: center;
  }

  .rule {
    .rule-value {
      .rule-value-list-item:not(:first-of-type) {
        margin-left: $rqb-spacing;
      }
    }
  }
}

// #region Drag-and-drop

// Hover styles
[data-inlinecombinators="disabled"] {
  .dndOver {
    &.rule,
    &.ruleGroup-header {
      border-bottom-width: $rqb-dnd-hover-border-bottom-width;
      border-bottom-style: $rqb-dnd-hover-border-bottom-style;
      border-bottom-color: $rqb-dnd-hover-border-bottom-color;
      padding-bottom: $rqb-spacing;

      &.dndCopy {
        border-bottom-color: $rqb-dnd-hover-copy-border-bottom-color;
      }
    }
  }
}
[data-inlinecombinators="enabled"] {
  .dndOver {
    &.rule:last-child,
    &.ruleGroup-header,
    &.rule + .betweenRules,
    &.betweenRules {
      border-bottom-width: $rqb-dnd-hover-border-bottom-width;
      border-bottom-style: $rqb-dnd-hover-border-bottom-style;
      border-bottom-color: $rqb-dnd-hover-border-bottom-color;
      padding-bottom: $rqb-spacing;

      &.dndCopy {
        border-bottom-color: $rqb-dnd-hover-copy-border-bottom-color;
      }
    }
  }
}

// Drag styles
.ruleGroup,
.rule {
  &.dndDragging {
    opacity: 0.5;
  }

  .queryBuilder-dragHandle {
    cursor: move;
  }
}

// When drag-and-drop is disabled, hide all drag handles
[data-dnd="disabled"] .queryBuilder-dragHandle {
  display: none;
}

// #endregion

// #region Branches

.queryBuilder-branches {
  .ruleGroup-body {
    margin-left: calc(2 * #{$rqb-branch-indent});
  }

  .rule,
  .ruleGroup .ruleGroup {
    position: relative;

    &::before,
    &::after {
      content: "";
      width: $rqb-branch-indent;
      left: calc(-#{$rqb-branch-indent} - #{$rqb-branch-width});
      border-color: $rqb-branch-color;
      border-style: $rqb-branch-style;
      border-radius: 0;
      position: absolute;
    }

    &::before {
      top: -$rqb-spacing;
      height: calc(50% + #{$rqb-spacing});
      border-width: 0 0 $rqb-branch-width $rqb-branch-width;
    }

    &:last-child::before {
      border-bottom-left-radius: $rqb-branch-radius;
    }

    &::after {
      top: 50%;
      height: 50%;
      border-width: 0 0 0 $rqb-branch-width;
    }

    &:last-child::after {
      display: none;
    }
  }

  .rule-operators {
    display: block;
    width: 100%;
    color: #575757;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e5e5ea54;
    box-shadow: 0px 1px 3px #32325d26;
    transition: border-color ease-in-out 0.3s;
    border-radius: 6px;
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    font-family: inherit;
    max-width: 120px;
  }
  .rule-fields {
    display: block;
    width: 100%;
    color: #575757;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e5e5ea54;
    box-shadow: 0px 1px 3px #32325d26;
    transition: border-color ease-in-out 0.3s;
    border-radius: 6px;
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    font-family: inherit;
    max-width: 220px;
  }

  .ruleGroup-combinators {
    display: block;
    width: 100%;
    color: #575757;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e5e5ea54;
    box-shadow: 0px 1px 3px #32325d26;
    transition: border-color ease-in-out 0.3s;
    border-radius: 6px;
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    font-family: inherit;
    max-width: 80px;
  }

  .rule-operators:focus,
  .rule-operators:hover,
  .rule-fields:focus,
  .rule-fields:hover,
  .ruleGroup-combinators:focus,
  .ruleGroup-combinators:hover {
    border-color: #2baebf;
    outline: 0;
  }

  .ruleGroup .ruleGroup {
    &::before,
    &::after {
      left: calc(
        calc(-#{$rqb-branch-indent} - #{$rqb-branch-width}) - #{$rqb-border-width}
      );
    }

    &::before {
      top: calc(-#{$rqb-spacing} - #{$rqb-border-width});
      height: calc(50% + #{$rqb-spacing} + #{$rqb-border-width});
    }

    &::after {
      height: calc(50% + #{$rqb-border-width});
    }
  }

  .betweenRules {
    position: relative;

    &::before {
      content: "";
      width: $rqb-branch-indent;
      left: calc(-#{$rqb-branch-indent} - #{$rqb-branch-width});
      border-color: $rqb-branch-color;
      border-style: $rqb-branch-style;
      border-radius: 0;
      position: absolute;
      top: -$rqb-spacing;
      height: calc(100% + #{$rqb-spacing});
      border-width: 0 0 0 $rqb-branch-width;
    }
  }
}

// #endregion

.queryBuilder {
  // Push the clone, lock, and remove buttons to the right edge
  .ruleGroup-addGroup + button.ruleGroup-cloneGroup,
  .ruleGroup-addGroup + button.ruleGroup-lock,
  .ruleGroup-addGroup + button.ruleGroup-remove,
  .rule-operators + button.rule-cloneRule,
  .rule-operators + button.rule-lock,
  .rule-operators + button.rule-remove,
  .rule-value + button.rule-cloneRule,
  .rule-value + button.rule-lock,
  .rule-value + button.rule-remove,
  .control + button.rule-cloneRule,
  .control + button.rule-lock,
  .control + button.rule-remove,
  .chakra-select__wrapper + button.rule-cloneRule,
  .chakra-select__wrapper + button.rule-lock,
  .chakra-select__wrapper + button.rule-remove {
    margin-left: auto;
  }
}

.query-codo-box {
  padding: 1em;
  background-color: #ebebeb;
  border: 1px solid #bcbcbc;
  margin-top: 1em;
  border-radius: 4px;
  font-size: 12px;
 
}
.query-codo-box > code{
  color:#575757;
}

.query-fake-input{
  display: block;
  width: 100%;
  color: #575757;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e5e5ea54;
  box-shadow: 0px 1px 3px #32325d26;
  transition: border-color ease-in-out 0.3s;
  border-radius: 6px;
  padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    text-align: start;
     -webkit-rtl-ordering: logical;
    cursor: text;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}
