html,
body {
  height: 100%;
  margin: 0px;
}

.ytp-chrome-controls {
  display: none !important;
  visibility: hidden;
  opacity: 0;
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 4px;
  height: 4px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #e4e4e42b;
}

::-webkit-scrollbar-track:hover {
  background-color: #e4e4e4;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  /* background-color: #e5ff00;*/
  border-radius: 16px;
  border: 2px solid #ccccccbf;
  width: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a2a6a6;
  border: 4px solid #a2a6a6;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

hr {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

img {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}

#root {
  height: 100%;
}

#canvas .row {
  height: 100%;
}

.mainCol {
  display: grid;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  padding: 10px;
}

@media screen and (max-width: 992px) {
  #canvas {
    height: calc(100vh - 212px);
    margin-bottom: 15px;
  }

  .footer {
    padding: 5px 0;
  }
}

@media screen and (min-width: 992px) {
  #canvas {
    height: calc(100vh - 180px);
    margin-bottom: 15px;
  }

  .footer {
    padding: 5px 0;
  }
}

.custom-header {
  padding-bottom: 5px !important;
  padding-top: 10px !important;
}

.sweet-alert {
  top: 30%;
}

.device-loading {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #717171;
  flex-direction: column;
}

.device-loading::before {
  position: absolute;
  bottom: 0;
  top: 0;
  background: #b3dfe596;
  content: "";
  width: 100%;
  pointer-events: fill;
  z-index: 9999;
  border-radius: 35px;
}

.device-notworking {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #717171;
  flex-direction: column;
}

.device-busy {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: transparent;
  flex-direction: column;
}

.device-busy::before {
  position: absolute;
  bottom: 0;
  top: 0;
  background: transparent;
  content: "";
  width: 100%;
  pointer-events: fill;
  z-index: 9999;
  border-radius: 35px;
}

.device-notworking::before {
  position: absolute;
  bottom: 0;
  top: 0;
  background: #b7adb033;
  content: "";
  width: 100%;
  pointer-events: fill;
  z-index: 9999;
  border-radius: 35px;
}

.device {
  -webkit-transform-origin: center top 0px;
  transform-origin: center top 0px;
  width: 407px;
  height: 787px;
  padding: 60px 16px;
  background: rgb(255, 255, 255);
  border-radius: 35px;
  box-shadow: #dddddda1 0px 0px 30px 0px;
  position: relative;
}

.deviceInside {
  height: 100%;
  background: rgba(226, 226, 226, 0.56);
  border: 1px solid rgb(224, 224, 224);
  max-height: 670px;
  overflow: hidden;
  overflow-y: auto;
}

#emulatorContainer {
  padding: 12px;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 8px 16px rgba(50, 50, 50, 0.3),
    inset -2px -4px 12px rgba(50, 50, 50, 0.2);
  position: relative;
  width: 321px;
  height: 640px;
}

.dynamicComponent {
  position: relative;
  display: flex;
  z-index: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}

.dynamicSubComponent {
  position: relative;
  display: block;
  z-index: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}

.dynamicSubComponent::after {
  content: "";
  display: table;
  clear: both;
}

.dragableComponent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.dragableComponent:active,
.dragableComponent:focus > .dragableComponentMore {
  display: block;
}

.dragableComponentHelper {
  display: none;
}

.dragableComponent:active,
.dragableComponent:focus > .dragableComponentHelper {
  display: block;
}

.dragableComponentMore {
  /*  
    display: none;
     outline: #2baebf solid 2px;
   */
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

/*
.dragableComponent:hover>.dragableComponentMore {
    display: block;
}
*/

.dragableComponentActions {
  /*  
    display: none;
   */

  position: absolute;
  font-size: 10px;
  z-index: 2;
  justify-content: space-evenly;
  flex-direction: row;
  top: 0;
}

.dragableComponent:active,
.dragableComponent:focus > .dragableComponentMore {
  display: block;
}

.dragableComponentButton {
}

.dragableComponentButton:hover > .dragableComponentMore {
  display: block;
}

/*Sidebar*/

.componentItem {
  margin-left: 9px;
  background-color: rgb(255, 255, 255);
  text-transform: uppercase;
  min-width: 95px;
  max-width: 95px;
  height: 95px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 13px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px;
  cursor: -webkit-grab;
  transition-duration: 0.15s;
  transition-property: box-shadow;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  flex: 1 1 0%;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  border-image: initial;
  border-radius: 4px;
}

.componentItemMini {
}

.componentItemMini > .componentItemIcon {
  color: rgba(85, 123, 128, 0.7);
}

.componentItemMini > .componentItemText {
  display: none;
}

.componentItem:hover {
  box-shadow: rgba(0, 0, 0, 0.247) 1px 6px 10px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  border-image: initial;
}

.componentItemContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  /*justify-content: space-between;*/
  align-content: flex-start;
  padding: 0.2px;
}

.componentItemContainerMini {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 10px;
}

.componentItemText {
  color: rgb(126, 126, 126);
  font-weight: 400;
  overflow-wrap: break-word;
  text-overflow: ellipsis;

  overflow: hidden;
  padding: 0px 6px;
}

.componentItemIcon {
  color: rgb(126, 126, 126);
  margin-bottom: 9px;
  font-size: 20px;
}

/* HELPER MENU*/

.contextMenu {
  position: fixed;
  z-index: 3;
  background: white;
  box-shadow: 0px 2px 10px #999999;
}

.contextMenu--option {
  padding: 6px 50px 5px 10px;
  min-width: 160px;
  cursor: default;
  font-size: 12px;
}

.contextMenu--option__disabled {
  color: #999999;
  pointer-events: none;
}

.contextMenu--separator {
  width: 100%;
  height: 1px;
  background: #cccccc;
  margin: 0 0 0 0;
}

.centerSide {
  display: flex;
}

.centerSideMain {
  flex: 1;
}

.rigthMenuSide {
  height: auto;
  background-color: #f8f9fe;
  border-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  display: flex;
  justify-content: flex-end;
}

.rigthMenuSideInside {
  width: 300px;
  transition: width 0.5s;
  position: relative;
  overflow: auto;
}

.rigthMenu-container {
  position: relative;
  overflow: auto;
  height: calc(100vh - 77px);
  overflow-x: hidden;
}

.rigthMenuSidePasive {
  width: 0px !important;
}

.rigthMenuSideTabs {
  /* box-shadow: 0px 3px 6px #00000029;*/
  width: 63px;
  background-color: #ffffff;
  border-left: 1px solid #fbfbfb;
}

.rigthMenuTab {
  padding-right: unset !important;
  cursor: pointer;
}

.rigthMenuTabInside {
  border-radius: unset !important;
}

.nav-wrapper2 {
}

.rigth-tab-shadow {
  margin-right: 1px;
  margin-bottom: 0px !important;
}

.rigth-tab-pane {
  min-height: calc(100vh - 125px);
}

.component-hover {
  padding: 5px;
  transition: padding 1s;
}

.component-hover::after {
  content: " ";
  border: 2px double #04ffde;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  display: block;
}

.property-dropdown-div {
  display: flex;
}

.property-dropdown-input {
  flex: 2 1 !important;
  transition: flex 0.5s;
}

.property-dropdown-input-display {
  flex: 0 0 !important;
  transition: flex 0.5s;
  padding: 0;
  border: none;
}

.property-dropdown {
  flex: 1 1;
  transition: flex 0.5s;
}

.property-dropdown2 {
  border: 1px solid #2baebf;
  color: #2baebf;
}

.property-dropdown2:hover {
  box-shadow: unset;
  transform: none;
  border: 1px solid #2baebfcf;
  color: #2baebfcf;
}

.property-dropdown > .btn-group {
  flex: 1 1;
  transition: flex 0.5s;
}

.property-dropdown-div > .property-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px !important;
}

.property-dropdown-div > .property-dropdown::before {
  content: "";
  display: inline-block;
}

.property-dropdown > .btn-group > .property-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px !important;
}

.property-dropdown > .btn-group > .property-dropdown::before {
  content: "";
  display: inline-block;
}

/* Sidebar Start 
.g-sidenav-hidden>#root>.sidenav:hover {
    max-width: 70px !important;
    width: 70px !important;
}
*/
.sidenav {
  overflow-x: hidden;
}

@media screen and (max-width: 600px) {
  .g-sidenav-pinned .sidenav {
    max-width: 240px !important;
  }

  .g-sidenav-hidden > #root > .sidenav:hover {
    width: 100% !important;
    max-width: 62px !important;
  }

  .componentItemContainerMini {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-content: flex-start;
  }

  .componentItemMini {
    background-color: rgb(255, 255, 255);
    text-transform: uppercase;
    min-width: 95px;
    max-width: 95px;
    height: 95px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 13px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px;
    cursor: -webkit-grab;
    transition-duration: 0.15s;
    transition-property: box-shadow;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    flex: 1 1 0%;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(221, 221, 221);
    border-image: initial;
    border-radius: 4px;
  }

  .componentItemMini > .componentItemIcon {
    color: rgb(126, 126, 126);
    margin-bottom: 9px;
  }

  .componentItemMini > .componentItemText {
    display: block;
  }
}

.sidenav-toggler {
  display: block !important;
}

.hamburgerMenu-Header {
  justify-content: space-between;
  padding-right: 10px;
}

.navbar-brand-img {
  font-family: "Open Sans", sans-serif;
}

.category-item {
  background: #f6f9fc;
  border-bottom: 1px solid #e4e8ec;
}

.category-icon-null {
  color: "white";
  background: #d2d2d2;
  text-align: center;
  border-radius: 6px;
  padding: 4px 0px;
}

.category-name {
  margin: auto;
  margin-left: 20px;
}

.nav-link2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  transition: padding 0.5s;
}

.nav-link2-active {
  padding: 0.675rem 1.3rem !important;
}

/* Sidebar End */

.noUi-tooltip {
  display: none;
}

.noUi-active .noUi-tooltip {
  display: block;
}

.noUi-horizontal {
  position: relative;
  margin-right: 20px;
  margin-left: 12px;
}

.noUi-tooltip {
  border-radius: 7px;
  padding: 5px;
  font-size: 11px;
}

.propertyTitleDiv {
  display: flex;
  justify-content: space-between;
}

.propertyTitle {
  display: flex;
  font-size: 14px;
}

.propertySubTitle {
  font-size: 14px;
  margin: 0;
  margin-left: 6px;
}

.propertyFontStyleToggle {
  box-shadow: none !important;
}

.sketch-picker {
  box-shadow: unset !important;
}

.color-picker-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: #fff;
  border-top: 1px solid #eee;
}

.property-form-group {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 10px;
}

.property-category-card {
  box-shadow: none !important;
  margin-bottom: 0px;
  overflow: unset !important;
}

.property-category {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0;
}

.property-category-title {
  color: #525f7f;
  font-size: 16px;
}

.property-category::after {
  right: 0.8rem !important;
  color: #cdd0d9;
}

.property-category-body {
  padding: 0.3rem;
  padding-top: 1.5rem;
}

.popover-colorpicker {
  background-color: transparent !important;
}

.popover-colorpicker > div {
  background-color: transparent !important;
}

.popover-colorpicker-body {
  padding: 0px;
}

.layout-treeview {
  cursor: pointer;
  border: 1px solid #b4e3e9;
  border-radius: 6px;
  padding: 5px 10px;
  color: #4ab2bf;
  text-indent: 0em;
}

.layout-treeview:hover {
  background-color: #b4e3e9;
  border-radius: 6px;
  padding: 5px 10px;
  color: #fff;
  text-indent: 0em;
}

.layout-treeview-caret {
  cursor: pointer;
  list-style: none;
  text-indent: -1em;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  position: relative;
}

.layout-treeview-caret::before {
  content: "\2295";
  color: #b4e3e9;
  display: inline-block;
  margin-right: 6px;
  position: absolute;
  top: 1px;
  font-size: 24px;
  margin-left: 5px;
}

.layout-treeview-ul {
  list-style-type: none;
  padding-inline-start: 15px;
}

.modal-open > #root {
  /*filter: blur(3px)*/
}

#root {
  /*  -webkit-transition: filter 500ms ease-out 1s;
    -moz-transition: filter 500ms ease-out 1s;
    -o-transition: filter 500ms ease-out 1s;
    transition: filter 500ms ease-out 1s;*/
}

.property-icon {
  width: 45px;
  height: 45px;
  background-color: #34b5c640;
  border: 2px double #7fcfda;
  border-style: dashed;
  border-radius: 6px;
  background-size: cover;
  position: relative;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.property-image {
  width: 45px;
  height: 45px;
  background-color: #34b5c640;
  border: 2px double #7fcfda;
  border-style: dashed;
  border-radius: 6px;
  background-size: cover;
  position: relative;
  margin-right: 5px;
}

.property-image-remove {
  width: 20px;
  height: 20px;
  background-color: #34b5c6;
  border-radius: 30px;
  position: absolute;
  right: -5px;
  top: -10px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #fff;
}

.property-image-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.icons-modal-box {
  padding: 10px;
  cursor: pointer;
  width: 90px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #dadada;
  border-radius: 4px;
  color: #a09e9e;
  margin: 5px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.icons-modal-box-icon {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons-modal-box-span {
  width: 70px;
  height: 23px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.icons-modal-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.gallery-modal-container {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: fit-content;
  margin-left: 2%;
}

.gallery-modal-container2 {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: fit-content;
  justify-content: center;
}

.dz-button {
  background: transparent !important;
}

.gallery-image-modal-box {
  padding: 0px;
  cursor: pointer;
  /* width: 134px;*/
  min-height: 86px;
  max-height: 86px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #dadada;
  border-radius: 4px;
  color: #a09e9e;
  margin: 5px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  position: relative;
}

.gallery-image-modal-box-controls::before {
  content: "";
  background: url(https://mobilex360-storage.s3.eu-central-1.amazonaws.com/storage/7db03473-9119-487e-81af-7aa5d4b687e9128x126.png);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.gallery-image-modal-box-img {
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.gallery-image-modal-box-controls {
  width: 100%;
  height: 100%;
  position: relative;
  background: #0af29b;
}

.gallery-image-modal-box-actions {
  display: flex;
  transition: height 0.1s;
  pointer-events: auto;
  justify-content: space-around;
  align-items: center;
  -webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-bottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.gallery-image-modal-box:hover > .gallery-image-modal-box-actions {
  -webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    both;
  animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.gallery-image-modal-box:hover > .gallery-image-modal-box-controls::after {
  content: "";
  position: absolute;
  background-color: #79b2ba96;
  width: 100%;
  height: 100%;
  top: 0;

  -webkit-animation: slide-in-fwd-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
    height: 0;
  }

  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
    height: 40px;
  }
}

@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
    height: 0;
  }

  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
    height: 40px;
  }
}

@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    height: 40px;
  }

  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
    height: 0;
  }
}

@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    height: 40px;
  }

  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
    height: 0;
  }
}

.button-group-icons {
  width: 14px;
  height: 14px;
}

.button-group-button {
  box-shadow: none !important;
  padding: 10px 15px !important;
}

.element-icons {
  vertical-align: text-bottom;
  margin: 0px 5px;
}

.element-icons > span {
  vertical-align: text-bottom;
}

.card-custom {
  margin: 0px !important;
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
}

.puff-out-center {
  -webkit-animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
  animation: puff-out-center 1s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

@-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

.global-property-content {
  padding: 10px 0px;
}

.global-props-text-preview-container {
  border: 2px dashed #8898aa;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-button-container-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-button-container-loading {
  position: absolute;
}

.global-props-container {
}

.global-props-detail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.global-props-detail-hr {
  width: 1px;
  height: 50px;
  background: #2baebf;
  display: flex;
  margin: 0px 30px;
}

.global-props-detail-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  color: #2baebf;
}

.global-props-list-item-h4 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.global-props-list-item-container {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px;
}

.select-font-size > span > span > span > span {
  font-size: 0.875rem !important;
}

.selection::after {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  font: normal normal normal 14px/1 NucleoIcons;
  line-height: 0;
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: "\ea0f";
  right: 0.8rem !important;
  color: #cdd0d9;
  pointer-events: none;
}

.select2-container--open > .selection::after {
  transform: rotate(180deg);
}

.dropdown-toggle::after {
  /* position: absolute;*/
  border-right: unset;
  border-top: unset;
  border-bottom: unset;
  border-left: unset;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  font: normal normal normal 14px/1 NucleoIcons;
  line-height: 0;
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  content: "\ea0f";
  right: 0.8rem !important;
  pointer-events: none;
}

.show > .dropdown-toggle::after {
  transform: rotate(180deg);
}

.color-picker-globalcolors {
  border-top: 1px solid rgb(238, 238, 238);
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 10px 10px 0px;
  background: white;
}

.color-picker-globalcolors-item {
  width: 16px;
  height: 16px;
  margin: 0px 10px 10px 0px;
}

.color-picker-globalcolor-item-div {
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
  outline: none;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}

.dropdown-global-fontsize {
  font-size: 14px;
  margin: 0;
  margin-left: 6px;
  padding: 0 5px;
  font-weight: 300;
  color: rgb(103, 114, 141);

  justify-content: center;
  align-items: center;
  display: flex;
}

.dropdown-global-fontsize-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  max-width: 80px;
}

.modal-sweet-alert {
}

.custom-popover-gif {
  width: 300px;
}

.custom-popover-content {
  width: 300px;
  padding: 8px;
  color: #7f7f7f;
}

.custom-popover-content > h5 {
  color: #7f7f7f !important;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4.5px;
}

.custom-popover-content > span {
  color: #787878 !important;
  font-size: 12px;
  line-height: 1;
}

.custom-popover-more {
  color: #2baebf !important;
  font-size: 12px;
  line-height: 1;
  display: flex;
  justify-content: flex-end;
}

.custom-popover-more > a {
  margin-right: 8px;
  cursor: pointer;
}

.custom-popover-more > a:hover {
  text-decoration: underline;
}

.dropzone {
  width: 90%;
}

.dz-message:hover {
  border: 2px dashed #11c5d9;
  color: #11c5d9;
}

.dz-message {
  border: 2px dashed #dee2e6;
  color: #11c5d9;
}

.dz-message {
  padding: 2rem 1rem;
}

.dz-message:hover > .dz-button {
  color: #11c5d9;
}

.dz-max-files-reached > .dz-message > .dz-button {
  background: transparent;
  color: #fff;
}

.dz-max-files-reached > .dz-message {
  background: transparent;
  color: #fff;
}

.dz-max-files-reached > .dz-message:hover {
  border: 2px dashed #dee2e6;
  color: #dee2e6;
}

.dz-max-files-reached > .dz-message {
  border: 2px dashed #dee2e6;
  color: #dee2e6;
}

.gallery-modal-content {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
}

.dropzone-single.dz-max-files-reached .dz-message {
  background-color: rgba(36, 151, 165, 0.55);
}

.slide-out {
  display: none;
}

.slide-in {
  display: block;
}

.cropper-tools-button {
  padding-left: 12px !important;
}

.cropper-tool-icon-rotate90 {
  -ms-transform: rotate(90deg);
  /* IE 9 */
  transform: rotate(90deg);
}

.cropper-tools-container {
  margin-top: 13px;
}

.cropper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rs-btn-toolbar > :not(:first-child):not(.rs-btn-block) {
  margin-left: 5px;
  /* border-left: 1px solid #2baebf26 !important;*/
  border-radius: 0px;
}

.modal-footer-p0 {
  padding: 0;
}

.modal-header-pb0 {
  padding-bottom: 0px;
}

.modal-header-flex {
  display: flex;
  align-items: center;
}

.modal-loading-container {
  background-color: #79b2ba96;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 6px;
  -webkit-animation: slide-in-fwd-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.custom-fade-in {
  animation: fadeIn 0.5s ease-in;
  -webkit-animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.notfound-stream-container {
  min-height: 96px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #8a98aa;
  font-size: 14px;
  font-style: italic;
}

.galleryTitleDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.galleryTitle {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #2baebf;
  background: transparent;
}

.pageBusy-container {
  padding: 0px 40px;
}

.pageBusy-container-row {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 5px 30px;
}

.pageBusy-title {
  color: #32325d;
  font-size: 24px;
  margin: 0px;
}

.pageBusy-header {
  padding-bottom: 5px !important;
}

.pageBusy-footer {
  justify-content: space-evenly !important;
}

.pageBusy-photo {
  border: 2px solid #0af29b;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-image: url("https://mobilex360-storage.s3.eu-central-1.amazonaws.com/storage/80147647-744d-4e6e-a19e-66addf3c820b600x646.png");
  background-position: center;
  background-size: contain;
}

.pageBusy-message {
  text-align: center;
  letter-spacing: 0px;
  color: #525f7f;
  font-size: 14px;
}

.gallery-crop-desc-row {
  width: 100%;
  margin: 10px;
}

.gallery-crop-desc-title {
  font-weight: 500;
}

/*
.slide-in>div:last-child>div:last-child {
    border-radius: 0.4375rem !important;
    overflow: hidden !important;
    left: 0px !important;
    right: 0px !important;
    top: 20px !important;
    bottom: 20px !important;
}
*/

.global-view {
  /*padding: 2em;*/
  display: flex;
  padding-top: 0em;
  /*min-height: calc(100vh - 132px);*/
  position: relative;
  animation: fadeIn 0.5s ease-in;
  -webkit-animation: fadeIn 0.5s ease-in;
  flex: 1;
  overflow: auto;
  overflow-x: hidden;
}

.project-container {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.project-col {
  border-bottom: 1px solid #70707014;
}

.project-title {
  font-size: 0.85rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.project-title > a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #8f8f8f;
}

.project-null-img {
  width: 80px;
  height: 80px;
  border: 4px dashed #2baebf;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 35px;
  font-weight: 100;
  color: #2baebf;
}

.project-img {
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #9aa7b76b;
}

.project-null-img:hover {
  cursor: pointer;
}

.project-img:hover {
  cursor: pointer;
}

.project-container:hover > .project-img {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.project-container:hover > .project-null-img {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.project-container:active > .project-img:hover {
  -webkit-animation: heartbeat 1.5s ease-in-out both;
  animation: heartbeat 1.5s ease-in-out both;
}

.project-container:active > .project-null-img:hover {
  -webkit-animation: heartbeat 1.5s ease-in-out both;
  animation: heartbeat 1.5s ease-in-out both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.project-create-btn {
  padding: 6px 26px;
  border-radius: 15px;
}

.project-search-input {
  background: #f1f1f1;
  padding-top: 3px;
}

.tickets-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none !important;
  background-color: transparent;
  line-height: 17px;
  text-align: center;
}

.tickets-tab > i {
  font-size: 30px;
  margin-right: 20px;
}

.nav-wrapper-custom {
  /*  padding: 1rem 5rem;*/
  border-bottom: 1px solid #d8d7d766;
}

.nav-wrapper-custom > ul {
  justify-content: space-around;
}

@media screen and (max-width: 720px) {
  .nav-wrapper-custom {
    padding: 1rem 0.75rem;
  }

  .tickets-tab {
    font-size: 12px;
    padding: 1rem 1.25rem;
  }

  .tickets-tab > i {
    font-size: 20px;
  }

  .nav-pills .nav-link {
    padding: 1rem 1.25rem;
  }
}

.nav-pills .nav-link {
  background-color: transparent;
  margin-bottom: 0px !important;
  padding: 1rem 1.75rem;
  width: 170px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
}

.nav-wrapper-customl > ul {
  justify-content: center;
}

.nav-custom-icon {
}

.ticket-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.ticket-main:hover {
  cursor: pointer;
  -webkit-animation: scale-down-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-down-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.ticket-main:hover > .ticket-title {
  text-decoration: underline;
}

.ticket-title {
  color: #888888;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
}

.ticket-detail {
  background: #f0fafb;
  border: 1px solid #e8f2f3c7;
  border-radius: 0.5rem;
  padding: 10px;
  font-size: 16px;
}

.ticket-detail > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.ticket-user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  border-top: 1px solid #d8d7d766;
  padding-top: 5px;
  font-size: 12px;
}

@-webkit-keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.995);
    opacity: 1;
  }
}

@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.995);
    opacity: 1;
  }
}

.ticket-pagination {
  justify-content: center;
  display: flex;
  padding-top: 20px;
}

.ticket-detail-main {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.ticket-detail-title {
  color: #888888;
  font-size: 23px;
  font-weight: 600;
  padding: 10px;
}

.ticket-detail-chat {
  height: 45vh;
  overflow-y: visible;
  position: relative;
  padding: 10px 15px;
}

.ticket-detail-chat-textbox {
  height: 10vh;
  margin-top: 15px;
  /*border: 1px solid #d5d5d573;*/
  border-radius: 6px;
  position: relative;
}

.ticket-detail-chat-textbox-actions {
  flex-direction: row;
  position: absolute;
  height: 100%;
  right: 20px;
  top: 0;
  display: flex;
  align-items: center;
}

.ticket-detail-chat-textbox-actions > button {
  background: transparent;
  color: #01b9cc;
}

.ticket-detail-chat-textbox-icons::before {
  /*   font-size:30px;  */
}

.ticket-detail-chat-content {
  display: flex;
  flex-direction: column;
}

.ticket-content-message {
  padding: 11px;
  font-size: 16px;
  font-weight: 100;
  border: 1px solid #d5d5d573;
  border-radius: 6px;
  margin-bottom: 3px;
}

.ticket-content1 {
  display: flex;
  flex-flow: row;
  margin-bottom: 10px;
}

.ticket-content2 {
  display: flex;
  flex-flow: row-reverse;
  margin-bottom: 10px;
}

.ticket-content1 > .ticket-detail-chat-content > .ticket-content-message {
  background: #01b9cc;
  color: #ffffff;
}

.ticket-content2 > .ticket-detail-chat-content > .ticket-content-message {
  background: #ffffff;
  color: #747474;
}

.ticket-content2 > .ticket-detail-chat-content {
  text-align: end;
}

.ticket-content-message-time {
  font-size: 12px;
  font-weight: 100;
  color: #9e9e9e;
}

.test {
  padding: 10px;
  background: #01b9cc;
}

.faq {
  padding-top: 8vh;
}

.faq-search {
  margin-bottom: 30px;
  position: relative;
}

.faq-search > input {
  height: 64px;
  padding-right: 55px;
  box-shadow: 0px 1px 19px #00000029;
}

.faq-seach-action {
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-search-icon {
  color: #d8d8d8;
  background: transparent !important;
}

.footer {
  background: none !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #d8d8d8 !important;
  font-weight: 100 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d8d8d8 !important;
  font-weight: 100 !important;
  font-size: 14px;
}

::placeholder {
  color: #d8d8d8 !important;
  font-weight: 100 !important;
  font-size: 14px;
}

.rs-picker-toggle-placeholder {
  color: #e0dfe0 !important;
  font-weight: 100 !important;
  font-size: 14px;
}

.rs-picker-toggle-value {
  color: #575757 !important;
  font-weight: 100 !important;
  font-size: 14px;
}

.faq-accordion {
  background-color: #0faebf;
  border-radius: 6px;
  padding: 20px 16px;
  color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: center;
}

.faq-accordion-header {
  color: #fff;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.faq-accordion-header:hover {
  cursor: pointer;
}

.faq-accordion-div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  padding: 0px 10px;
  flex: 1;
}

.faq-accordion-icon {
  color: white;
  font-size: 35px !important;
  transition: transform 0.1s;
}

.faq-accordion-icon-active {
  color: white;
  font-size: 35px !important;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: transform 0.1s;
}

.faq-accordion-detail {
  background-color: transparent;
  margin: 0px;
}

.faq-accordion-detail > .card-body {
  padding: 1rem;
  padding-top: 0px;
  padding-bottom: 0px;
}

.faq-no-result-found {
  margin-top: 10px;
  text-align: center;
  color: #b9b4b4;
}

.profile-settings-profile-photo-main {
  display: flex;
  justify-content: center;
  align-content: center;
}

.avatar-container-viewer {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  max-width: 112px;
  max-height: 112px;
}

.avatar-container-editable {
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
}

.avatar-img {
  width: 80px;
  height: 80px;
  border-radius: 20px;
  border: 1px solid #9aa7b76b;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.avatar-img-hover {
  display: none;
}

.avatar-container-editable:hover > .avatar-img-hover {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #a6dfe5a3;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 20px;
  text-shadow: 1px 2px 1px #a7a7a7;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.avatar-container-editable:active > .avatar-img-hover {
  -webkit-animation: heartbeat 1.5s ease-in-out both;
  animation: heartbeat 1.5s ease-in-out both;
}

.avatar-container-editable:hover > .avatar-img {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.avatar-container-editable:hover > .project-null-img {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.avatar-container-editable:active > .avatar-img {
  -webkit-animation: heartbeat 1.5s ease-in-out both;
  animation: heartbeat 1.5s ease-in-out both;
}

.avatar-container-editable:active > .project-null-img {
  -webkit-animation: heartbeat 1.5s ease-in-out both;
  animation: heartbeat 1.5s ease-in-out both;
}

.custom-form-horizontal {
  display: flex;
}

.custom-form-horizontal > .rs-form-group {
  flex: 1;
}

.custom-form-horizontal > .rs-form-group:not(:first-child) {
  margin-left: 24px;
}

@media screen and (max-width: 700px) {
  .custom-form-horizontal {
    flex-direction: column;
  }

  .custom-form-horizontal > .rs-form-group:not(:first-child) {
    margin-left: 0px;
    margin-bottom: 24px;
  }
}

.profile-settings-title {
  color: #8898aa;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 32px;
}

.profile-pp {
  margin-bottom: 24px;
}

.icon-info {
  background-color: transparent !important;
}

.icon-info > i {
  color: #0e97a5;
}

.icon-edit {
  color: #b7b7b7;
  background-color: transparent !important;
}

.icon-edit > i {
  color: #b7b7b7;
}

.icon-delete {
  color: #ff9696;
  background-color: transparent !important;
}

.icon-delete > i {
  color: #ff9696;
}

.table-badge-admin {
  color: #fff;
  background-color: #ef7380;
  text-transform: uppercase;
}

.table-badge-senior {
  color: #fff;
  background-color: #5eb6bd;
  text-transform: uppercase;
}

.table-badge-junior {
  color: #8e94a0;
  background-color: #dde2e4;
  text-transform: uppercase;
}

.member-edit-header {
  width: 100%;
  padding: 0 2em;
}

.member-edit-header > .card {
  box-shadow: none;
  margin-bottom: 0px;
}

.member-edit-header > .card > .card-body {
  padding: 0px;
}

.member-role-div {
  border: 1px solid #0e97a5;
  color: #0e97a5;
  font-weight: 600;
  font-size: 63px;
  border-radius: 7px;
  width: 74px;
  height: 74px;
  text-align: center;
  margin-top: 5px;
}

.member-role-s {
  line-height: 1.15;
}

.member-role-j {
  line-height: 0.9;
  padding-left: 5px;
}

.member-info-name {
  font-weight: 100;
  font-size: 18px;
  color: #707070;
}

.member-info-action-row {
  border-top: 1px solid #e5e5ea;
}

.member-info-action-row > button {
  font-size: 13px;
  color: #8898aa;
  font-weight: 600;
  padding: 12px 12px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.modal-title {
  font-weight: normal;
}

.create-project-type-option {
  width: 100%;
}

.create-project-type-divider {
  height: 40%;
  width: 1px;
  background: #f2f4f6;
  margin-top: 20%;
  display: none;
}

.create-project-types > :not(:first-child) > .create-project-type-divider {
  display: block;
}

.create-project-type {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #8898aa;
  padding: 1rem 3rem;
  cursor: pointer;
}

.create-project-type > p {
  font-size: 13px;
  font-weight: 600;
}

.create-project-type-icon {
  stroke: #8898aa;
  transition: all 1s;
}

.create-project-type:hover {
  animation: strokeColor 1s forwards;
  -webkit-animation: strokeColor 1s forwards;
  /* color: #0FAEBF;*/
}

.create-project-type:active {
  -webkit-animation: heartbeat 2s ease-in-out both;
  animation: heartbeat 2s ease-in-out both;
  stroke: #0faebf;
}

@keyframes strokeColor {
  0% {
    stroke: rgba(136, 152, 170, 0.13);
  }

  100% {
    stroke: #0faebf;
  }
}

@-webkit-keyframes strokeColor {
  0% {
    stroke: rgba(136, 152, 170, 0.13);
  }

  100% {
    stroke: #0faebf;
  }
}

.create-project-type:hover > p {
  color: #0faebf;
}

.create-project-type-icon-svg {
  height: 200px;
}

.create-project-type-icon-svg > span > svg {
  height: 100%;
}

.create-project-types {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.project-theme-box {
  width: 100px;
  height: 100px;
  border: 1px solid #f2f4f6;
  border-radius: 6px;
}

.project-theme-slick {
  position: relative;
  max-width: 100%;
  margin-bottom: 24px;
  display: flex;
  overflow-x: auto;
}

.project-theme-slick-div {
  /* margin: 0px 20px;*/
  display: flex;
  justify-content: center;
}

.create-input-row {
  padding: 3rem;
}

.creator-toobar {
  display: flex;
  flex-direction: row-reverse;
}

.creator-toobar > button {
  margin-left: 10px;
}

.nav-wrapper-custom-payment > ul {
  justify-content: center;
}

.nav-wrapper-custom-payment > ul > li {
  position: relative;
}

.nav-wrapper-custom-payment > ul > li > .nav-link {
  background-color: transparent;
  margin-bottom: 0px !important;
  padding: 1rem;
  font-size: 20px;
  width: auto;
  color: #dbe0e5;
  border: none !important;
  transition: color 1s;
}

.nav-wrapper-custom-payment > ul > li > .nav-link:hover {
  color: #dbe0e5;
}

.nav-wrapper-custom-payment > ul > li > .nav-link.active {
  color: #8898aa;
}

.navbar-nav > .nav-item > .nav-link {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border-radius: 0.375rem;
  padding: 0.675rem 1rem !important;
}

.navbar-nav > .active > .nav-link {
  background: #f6f9fc;
}

.navbar-nav > .active > .nav-link > i {
  color: #2baebf !important;
}

.nav-wrapper-custom-payment > ul > li:last-child {
  display: flex;
  align-items: center;
}

.nav-wrapper-custom-payment > ul > li:last-child::before {
  content: "";
  position: absolute;
  background: #dbe1e5;
  height: 30px;
  width: 1px;
}

.credicard-date {
  flex: 1;
  background-color: rgba(34, 136, 149, 0.5);
  border-color: #228895;
}

.credicard-date > a {
  background-color: rgba(34, 136, 149, 0.5) !important;
  border-color: #228895 !important;
}

.credicard-date > a > .rs-picker-toggle-caret {
  left: 12px;
  color: #fff;
}

.credicard-date > a > .rs-picker-toggle-clean {
  right: 12px;
}

.credicard-date > a > .rs-picker-toggle-value {
  margin-left: 30px;
}

.credicard-main {
}

.add-credit-card-title {
  text-align: center;
  margin-bottom: 24px;
  color: #8898aa;
}

.table-title-with-action {
  display: flex;
  justify-content: space-between;
}

.modal-form {
  width: 100%;
}

.modal-form-padding {
  padding: 1.5rem;
  padding-top: 0;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.collections-sidebar {
  border-right: 1px solid #f6f6f6;
  padding: 1em;
  position: relative;
  max-width: auto;
  -webkit-transition: 1s;
  transition: all 1s;
}

.collections-sidebar-mini {
  max-width: 20px !important;
}

.collections-sidebar-mini > ul {
  visibility: hidden;
}

.collections-container {
  padding: 0 1em;
}

.collections-sidebar-menu {
  width: 100%;
}

.cell-header {
  text-transform: capitalize;
}

.picker-full {
  width: 100%;
}

.delete-modal {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.delete-modal > i {
  font-size: 4em;
  margin-bottom: 25px;
  color: #88bdc49c;
}

.delete-modal > p {
  font-size: 1.2em;
  color: #9d9da0;
  text-align: center;
}

.side-loading-container {
  /* background-color: #79b2ba96;*/
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  border-radius: 6px;
  -webkit-animation: slide-in-fwd-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.null-message {
  text-align: center;
  padding-top: 2rem;
  color: #babfbf;
}

.null-message > i {
  font-size: 2rem;
  margin-bottom: 8px;
}

.table-div {
  animation: fadeIn 0.5s ease-in;
  -webkit-animation: fadeIn 0.5s ease-in;
  flex: 1;
  padding: 1rem;
}

.ctable .rs-table-cell-last {
  left: auto !important;
  right: 0px !important;
}

.collections-toggle-button {
  position: absolute;
  right: -15px;
  top: 5rem;
  border: 1px solid #f6f6f6;
  border-radius: 50%;
  z-index: 6;
}

.collections-add-button {
  position: absolute;
  bottom: 2rem;
  z-index: 5;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.collections-add-button > button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  background: #f8fcff;
  border: 1px solid #9090902e;
  border-radius: 20px;
  padding: 1rem;
  width: 37px;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
  pointer-events: all;
}

.collections-add-button > button > i {
  color: #2badbe;
}

.collections-add-button > button > p {
  margin: 0px;
  margin-left: 10px;
  visibility: hidden;
  position: absolute;
  font-size: 15px;
  color: #a5a5a5;
}

.collections-add-button > button:hover {
  width: 80%;
  background: #f8fcff;
  -webkit-transition: width 0.1s;
  transition: width 0.1s;
}

.collections-add-button > button:hover > p {
  visibility: visible;
  position: relative;
  width: auto;
}

.collections-add-button > button:focus {
  width: 80%;
  background: #f8fcff;
  -webkit-transition: width 0.1s;
  transition: width 0.1s;
}

.collections-add-button > button:focus > p {
  visibility: visible;
  position: relative;
  width: auto;
}

.collections-sidebar-mini > .collestions-search {
  visibility: hidden;
}

.collestions-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.collestions-divider {
  margin: 20px 0;
  background-color: #f6f6f6;
}

.custom-cell {
  margin: 0px;
  color: #878787;
  font-size: 0.9em;
  padding: 0 !important;
  text-align: left;
}

.custom-cell-bool {
  margin: 0px;
  color: #2baebf;
  font-size: 0.9em;
}

.create-collection {
  padding-bottom: 1em;
}

.typePopover {
  padding: 1em;
}

.typeName {
  font-weight: 600;
  margin-bottom: 7px;
}

.typeDetail {
}

.modal-lg,
.modal-md {
  -webkit-transition: max-width 0.5s !important;
  transition: max-width 0.5s !important;
}

.custom-modal-footer {
  display: flex;
  flex-direction: row-reverse;
}

.dm-card-title {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 0;
}

.dm-card-subtitle {
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 0;
}

.dm-card-text {
  font-weight: 100;
  font-size: 13px;
  margin-bottom: 5px;
}

.dm-card {
  display: flex;
  flex-direction: row;
  border: 1px solid #8e8e93;
  border-radius: 6px;
  padding: 1em;
  justify-content: space-around;
}

.dm-count {
  margin-left: 10px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.dm-count-text {
  font-size: xx-large;
  font-weight: 600;
  margin-bottom: 5px;
  color: #8e8e93;
}

.global-dm-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.global-dm-panel {
  border: 1px solid #f2f2f5 !important;
  background: #fbfbfb;
  margin-bottom: 1em;
}

.global-dm-panel::before {
  content: none !important;
}

.global-dm-panel-title {
  margin-bottom: 0;
  font-weight: 500;
}

.global-dm-panel-text {
  font-size: 12px;
  margin-bottom: 0;
}

.edit-table-priority {
  /*  flex-direction: row;*/
  /*  display: flex; */
  /*  height: 100px; */
}

.edit-table-priority-row {
  padding: 0.5em;
  background: #ececec;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  margin: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.edit-table-priority-row > i {
  margin-right: 10px;
}

.edit-table-priority-row > div > p {
  margin-bottom: 0;
}

.edit-table-priority-row > div > p:last-child {
  font-size: 13px;
}

.no-database {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex: auto;
}

.no-database-button {
  border: 1px solid !important;
  border-radius: 20px !important;
}

.no-database-icon {
  font-size: 6em;
  color: #dcdcdc5e;
  margin-bottom: 2rem;
}

.no-database > button {
  margin-top: 1rem;
}

.ctable-last-column > div {
  left: auto !important;
  right: 0 !important;
}

.ctable-last-column {
  left: auto !important;
  right: 0 !important;
}

.dynamic-create-form > div:last-child {
  padding-bottom: 20px;
}

.custom-white-tooltip > div > p {
  color: red !important;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  -webkit-filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.1));
  padding: 4px 8px;
}

.custom-white-tooltip > .rs-popover-arrow {
  margin-top: -5px;
}

.dynamic-dt-side {
  position: relative;
  margin-bottom: 10px;
}

.dynamic-dt-popover {
  position: relative;
}

.dynamic-enum-side {
  margin-top: 0;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #f5f5f6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rs-tag-group > .rs-tag {
  margin-top: 0px;
}

.dynamic-enum-side > div {
  margin: 0px !important;
}

.workspace-sidebar {
  border-right: 1px solid rgb(136 152 170 / 7%);
  flex: 0 0 230px;
  width: 230px;
  position: relative;
}

.service-tree-nodes::-webkit-scrollbar {
  width: 2px !important;
}

.service-tree-nodes::-webkit-scrollbar-thumb {
  border: 6px solid #ccccccbf;
}

.service-tree-nodes::-webkit-scrollbar-track {
  background-color: #e4e4e42b;
}

.service-sidebar {
  border-right: 1px solid rgb(136 152 170 / 7%);
  flex: 0 0 150px !important;
}

.service-tab > ul > li > a {
  padding: 1em !important;
}

.main-layout-inside {
  max-height: calc(100vh - 90.59px);
  height: calc(100vh - 90.59px);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

.service-tree-layout {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1em 0px 1em 1em;
  max-height: calc(100vh - 200px);
  padding-bottom: 0;
}

.main-footer-side {
  flex: 1;
  max-height: 48px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.service-tree-nodes {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.service-tree-nodes > .rs-tree {
  max-height: calc(100vh - 307px);
  height: 100% !important;
}

.service-sidebar-button {
  position: absolute !important;
  top: 4%;
  right: -15px;
  border: 1px solid rgb(242, 243, 243) !important;
  z-index: 5;
}

.nav-service-header {
  min-height: 56px;
  padding: 0px 1em;
  border-bottom: 1px solid rgb(247, 248, 249);
}

.nav-service-navbar-header {
  display: flex !important;
  align-items: center;
}

.header-nav-buttons {
  height: 56px;
  display: flex;
  align-items: center;
}

.script-text {
  font-family: Menlo, Monaco, "Courier New", monospace;
  font-weight: normal;
  font-size: 12px;
  font-feature-settings: "liga" 0, "calt" 0;
}

.mvc-div {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1em;
  max-height: calc(100vh - 235px);
  overflow-y: scroll;
}

.script-code-side {
  flex: 1 1 0%;
  height: 100%;
  border: 1px solid rgb(222, 226, 230);
  max-height: 100%;
  position: relative;
}

.script-text-blue {
  color: #0000ff;
}

.script-text-grey {
  color: #939392;
}

.script-text-black {
  color: #000000;
}

.script-title {
  margin-bottom: 0.2rem;
  margin-top: 0.5rem;
  font-size: 15px;
  font-weight: 500;
  color: #2baebf;
}

.script-title-info {
  margin-left: 5px;
  color: #d9d9d9;
}

.view-designer-card {
  position: relative;
  border: 1px dashed #e5e5ea;
  margin-bottom: 18px;
  -webkit-transition: border-color 0.3s linear;
  transition: border-color 0.3s linear;
}

.view-designer-card-wrapper {
  padding: 18px 18px 28px;
  background: #fff;
}

.view-designer-card-header {
  height: 30px;
  background: rgb(246, 249, 252);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
}

.view-designer-card-header > span {
  font-size: 14px;
  color: #9e9ea3;
}

.move-handle {
  color: #44b8c7;
  cursor: pointer;
}

.move-handle:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.sortable-ghost {
  cursor: grabbing !important;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.sortable-chosen {
  cursor: grabbing !important;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.not-match-workspace {
  padding: 2em !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.not-match-workspace > i {
  margin-bottom: 1rem;
  color: #ffc107;
}

.not-match-workspace > span {
  margin-bottom: 1rem;
  color: #dedede;
  font-size: 2rem;
}

.no-workspaces {
  min-height: 100px;
  widows: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 1em;
  flex-direction: column;
  align-items: center;
  color: #a3a3a3;
  min-width: 100%;
  width: 100%;
}

.no-workspaces > i {
  color: #cbcbcb;
  margin-bottom: 20px;
}

.no-workspaces-button {
  border: 1px solid !important;
  border-radius: 30px !important;
  margin-top: 20px;
  padding-left: 38px !important;
}

.no-workspaces-button > i {
  margin-left: 5px;
}

.select-module-main {
}

.trans-tree {
}

.trans-tree > .rs-tree-nodes > .rs-tree-node {
  padding-left: 0px !important;
}

.trans-tree > .rs-tree-nodes > .rs-tree-node > .rs-tree-node-label {
  padding-left: 0px !important;
}

.trans-tree-node {
  display: flex;
  flex-direction: column;
}

.trans-tree-node-name {
}

.trans-tree-node-code {
  font-size: 0.7em;
  color: #2aafbe;
}

.store-item {
  text-align: left;
  text-align: start;
  white-space: break-spaces;
  box-shadow: none !important;
}

.store-item > .rs-panel-heading {
  padding: 10px;
  padding-bottom: 5px;
}

.store-item > .rs-panel-body {
  font-size: 0.9em;
  padding: 10px;
  padding-top: 0px;
}

.store-add-button {
}

.store-item > .rs-panel-heading .rs-panel-title {
  display: flex;
  justify-content: start;
  align-items: center;
}

.store-item > .rs-panel-heading .rs-panel-title > .store-item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.store-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.store-header > span {
}

.store-header-sub {
  display: flex;
  justify-content: right;
}

.store-header-input {
  box-shadow: none !important;
  border: 1px solid #e5e5ea54;
  border-radius: 6px;
}

.store-not-found {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #c1c1c1;
}

.store-not-found > i {
  font-size: 3.5em;
  color: #f2f2f2;
  margin-bottom: 6px;
}

.store-model-header {
  padding-bottom: 5px !important;
}

.store-model-body {
  padding-top: 5px !important;
}

.lightbox-container {
  z-index: 1050 !important;
}

.store-header-title {
  display: flex;
  flex-direction: column;
}
.store-header-title > span {
  font-size: 0.8em;
  color: #a4a4a4;
  padding-left: 3px;
}

.view-table > .rs-table-body-row-wrapper {
  /*   height: 100% !important;*/
  /* overflow: auto !important;*/
}

.view-table {
  /*   height: 100% !important;*/
  min-height: 200px;
}
.rs-table-body-wheel-area {
  /*   height: 100% !important;*/
}
.rs-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rs-table-scrollbar-vertical {
  width: 6px !important;
}
.rs-table-scrollbar-vertical .rs-table-scrollbar-handle {
  width: 6px !important;
}
.rs-table-scrollbar-pressed .rs-table-scrollbar-handle {
  background-color: #9e9e9e !important;
}
.refFormControl > .rs-panel-body {
  padding-bottom: 4px;
}
.refFormControl > .rs-form-control-wrapper {
  top: -17px;
}

.engine-box-flex {
  padding-bottom: 0.8em;
  padding-right: 0.8em;
}

.engine-box {
  min-height: 100px;
}

button {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal !important;
}

iframe {
  display: none !important;
}

.delete-form-data .rs-form-control-wrapper.plaintext > .rs-input {
  box-shadow: none !important;
}

.engine-status-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.engine-status-box > span {
  margin-left: 4px;
  font-size: 0.75em;
  color: #c1c1c1;
}
.engine-status-color {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #dedede;
  margin-left: 1px;
}

.engine-status-on {
  background-color: #8bc34a;
  -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
  animation: pulsate-bck 0.5s ease-in-out infinite both;
}
.engine-status-off {
  background-color: #ff5252;
}
.engine-status-loading {
  background-color: #e9d312;
  -webkit-animation: blink-1 0.6s infinite both;
  animation: blink-1 0.6s infinite both;
}

@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.4;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.4;
  }
}
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.modal-lio-body > .modal-footer {
  padding-bottom: 0 !important;
}

.role-card {
  display: flex;
  padding: 1em;
  flex-direction: column;
  border: 1px solid #9fdae266;
  margin-bottom: 0.75em;
  background: #e2f4f654;
  border-radius: 4px;
}

.role-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.75em;
  font-size: 0.9em;
  font-weight: 600;
  color: #2baebf;
}

.role-button {
  background: #dde9ea !important;
  color: #2baebf !important;
}
.role-tag {
  background: #9edae2 !important;
  color: white !important;
}

.users-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.75em;
  font-size: 0.9em;
  color: #949494;
}
.users-card {
  display: flex;
  padding: 1em;
  flex-direction: column;
  border: 1px solid #22232314;
  margin-bottom: 0.75em;
  background: #dddddd24;
  border-radius: 4px;
  width: 100%;
}

.user_roles-title {
  color: #202020;
  font-size: 0.8em;
  margin-bottom: 5px;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 1px solid #9e9e9e33;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user_roles-card {
  font-size: 0.8em;
  color: #2592a1;
  padding: 5px 10px;
  margin-bottom: 5px;
  border-bottom: 1px solid #d3e9ec;
  background: #d3e9ecb0;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.role-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.react-swipe-container > div {
  height: 50vh;
}
.react-swipe-container {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.react-swipeable-view-container {
  height: 100%;
}
.global-script-view {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.global-scriptfile {
  height: 100%;
}
.global-scriptfile .rs-panel-heading {
  padding-bottom: 10px;
}
.global-scriptfile .rs-panel-body {
  padding: 10px;
}
.global-scriptfile .rs-panel-body > p {
  margin-bottom: 2px;
  line-height: 1.3;
  text-align: center;
  font-size: 90%;
}
.global-scriptfile-create {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8fd0d9;
  min-height: 108px;
  font-size: 1.2em;
}
.global-scriptfile-create-div {
  font-size: 0.9em;
  margin-top: 5px;
  color: #2baebf;
}

.btn-remove-relation {
  position: absolute;
  right: -5px;
  top: -5px;
}

.custom-modal-footer {
  padding: 1em;
}

.custom-modal-footer button {
  margin-left: 10px !important;
}

.httpobject-modal {
}

@media (max-width: 992px) {
  .httpobject-modal {
    margin: 1.75rem auto;
    padding: 0.75rem;
    max-width: none;
  }
}

.httpobject-modal * .modal-header {
  padding-bottom: 0px;
}

.httpobject-headers-table {
}

.httpobject-headers-table * table {
  table-layout: fixed;
}

.httpobject-headers-table * td > span {
  white-space: pre-line;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-genarator-p {
  padding-bottom: 0;
}

.multi-avatar {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.multi-avatar > div {
  margin-right: 10px;
  margin-bottom: 10px;
}

.avatar-readOnly-null {
  width: 80px;
  height: 80px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eaeaef;
  border: 1px solid #d1d1d7;
  margin-right: 10px;
}

.avatar-container-viewer {
  margin: 0;
  padding: 0;
}

.refModelSearch {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 15px;
  right: 10px;
}

.cbox-allow-trans {
  font-size: 0.8em !important;
}

.cbox-allow-trans > label {
  color: #939398;
}

.terminal-tabs {
}
.terminal-tabs ul {
  display: flex;
}

.terminal-tabs li {
  flex: 1;
  border: none;
}

.terminal-tabs .rs-nav-item-content {
  border: none !important;
  white-space: nowrap !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.terminal-tabs .rs-nav-item > a {
  border: none !important;
  background: transparent !important;
  color: #58565b !important;
  font-size: 12px;
  font-family: "Open Sans";
}

.terminal-tabs .rs-nav-waterline {
  display: none;
}

.terminal-tabs .rs-nav-item {
  border: none !important;
  background: #161419 !important;
  border-radius: 0;
  border-right: 1px solid #423e47 !important;
}

.terminal-tabs li:last-child {
  border-right: 1px solid transparent !important;
}
.terminal-tabs .rs-nav-item-active {
  background: #2d2932 !important;
}
.terminal-tabs .rs-nav-item-active > a {
  background: transparent !important;
  color: white !important;
}
.terminal-modal {
  background: #2e2a34;
  color: white;
}

.terminal-modal > button > span {
  color: white !important;
}
.terminal-modal > .modal-title {
  color: white !important;
}

.terminal-modal-body {
  background: #2e2a34;
  padding-top: 0;
  height: 100%;
}

.terminal-modal-body > .rs-container {
  padding: 4px;
  border-radius: 6px;
  overflow: hidden;
}

.terminal-drawer .terminal-modal {
  background: #2e2a34 !important;
  margin: 0px !important;
  padding: 1em;
}

.terminal-drawer .terminal-modal .rs-drawer-header-close {
  top: 1.5em !important;
  right: 1.5em !important;
  color: #b8b5b5 !important;
}

.terminal-drawer .terminal-modal .rs-drawer-title {
  color: #b8b5b5 !important;
}

.terminal-drawer .rs-drawer-content {
  background: #2e2a34 !important;
}
.unvisible-modal {
  z-index: -999;
}

.rs-drawer-wrapper {
  position: absolute !important;
}
.terminal-drawer-close {
  display: none !important;
}
.terminal-drawer-bd {
}
.terminal-drawer-bd-close {
  display: none;
}

.terminal-drawer-h {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.terminal-drawer-h > h4 {
  width: auto !important;
  margin-right: 10px !important;
}

.terminal-drawer-h > button {
  background: #47424e !important;
  border: 1px solid #19141c;
  color: #b5b2b2;
}

.react-swipeable-view-container {
  padding-right: 6px;
}

.import-field-key {
  margin: 5px;
  margin-bottom: 10px;
  padding: 8px;
  /* background: #f5f5f594;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  */
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f6f6f6;
}

.import-field-text {
  width: 160px;
  padding-right: 4px;
}

.import-field-text-divider {
  padding: 0em 1em;
}

.import-field-key:last-child {
  border-bottom: none;
}

.import-field-custom-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}

.import-field-custom-operationtype {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0;
}

.rs-picker-cascader-menu-column {
  width: auto !important;
  font-size: 0.75em;
}

.rs-picker-cascader-menu-items > div {
  width: auto !important;
}

.import-field-custom-container .separator {
  position: inline !important;
}

.menu-carusel-trans .slick-arrow {
  display: none !important;
}

.slick-slide {
  padding: 1px !important;
}

.workspace-footer {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-top: 1px solid rgba(136, 152, 170, 0.07);
}
.advanced-box .rs-panel-heading {
  padding: 0 1em;
}
.form-element-advanced {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.form-element-advanced-options {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}
.form-label-advanced {
  margin-bottom: 10px;
  color: #525f7f;
  font-size: 14px;
  font-weight: 600;
}

.form-label-base-advanced {
  display: flex;
  flex-direction: row;
  /*justify-content:space-between;*/
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

.form-label-base-advanced > span {
  margin-bottom: 0;
  margin-right: 10px;
}

.modal-header-extra {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
}
.modal-header-sub {
  display: flex;
  align-items: center;
}
.modal-body {
  min-height: 68px;
}

.refFormControl > .elementList {
  box-shadow: 0px 1px 3px #32325d26;
  transition: border-color ease-in-out 0.3s;
}

.elementList .rs-list-item-content {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.elementList .empty-list-item > .rs-list-item-content {
  font-size: 14px;
  font-style: italic;
  color: #cdcdcd;
}

.endpoint-modal-body {
  border: 1px solid #ededed;
  border-left: 0;
  border-right: 0;
  padding-top: 1em !important;
}

.endpoint-modal-body::-webkit-scrollbar {
  width: 10px !important;
  background-color: #f6f6f6;
  padding: 2px;
}

.endpoint-modal-body::-webkit-scrollbar-thumb {
}

.search-filter-form-panel {
  margin-top: 1em;
  padding: 0;
}

.search-filter-form-panel > .rs-panel-body {
  padding: 0;
}

.search-filter-form-panel > .scrollbar-container {
  padding: 0 !important;
}

.lang-setting-modal .rs-modal-dialog {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.lang-setting-modal .rs-modal-content {
  padding: 0px !important;
  padding-top: 20px !important;
}
.lang-setting-modal .rs-modal-header {
  padding: 0px 20px !important;
}

.lang-setting-modal .rs-modal-body {
  padding: 0;
  margin-top: 10px;
}

.lang-setting-modal .react-swipeable-view-container {
  padding: 0 !important;
}

.lang-label .rs-table-cell-content {
  font-size: 0.7em;
}

.langs-headers {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  justify-content: space-between;
  align-items: center;
}

.langs-list-option .rs-list-item-content {
  width: 100%;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lang-button-tools {
  display: flex;
  gap: 10px;
  align-items: center;
}

.console-tab-log {
  max-height: 31.5vh;
  min-height: 31.5vh;
  border-radius: 6px;
  max-width: 100%;
  overflow: auto;
  cursor: text;
  background-color: rgb(33, 33, 33);
  background-size: cover;
  position: relative;
}
.console-tab-log-clear {
  position: absolute !important;
  bottom: 10px;
  right: 10px;
}
.workspace-panel > div {
  display: flex;
  gap: 4px;
  justify-content: center;
  color: #aaa;
}
.workspace-panel .w-title {
  font-weight: 500;
  color: #626262e0;
}

.wBoxButton .rs-panel-heading {
  color: #5cc1ce !important;
}

.wBoxButton {
  width: 100%;
  padding: 0 !important;
  background: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
}
.wBoxButton:hover {
  transition-duration: 100ms;
  transition-property: all;
  background: #5cc1ce18 !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  transform: scale(1.02);
}

.wBoxButton:hover .rs-panel-heading {
  text-decoration: underline;
}

.wBoxButton > .rs-ripple-pond {
  display: none !important;
}

.wBoxButton .rs-panel-body {
  padding-bottom: 4px;
  padding-left: 0px;
  padding-right: 0px;
}

.wBoxButton .rs-panel-body .workspace-panel {
  padding: 0 4px;
}

.wButtonToolbar {
  margin-top: 1em;
  padding-bottom: 4px;
}

.store-item-badge {
  padding: 2px 10px !important;
  position: absolute !important;
  right: 40%;
  top: 0;

  font-weight: 600;
  border-radius: 6px !important;
}

.wPanel > .rs-panel-heading {
  padding-bottom: 4px;
}

.wPanel > .rs-panel-body {
  padding-bottom: 8px;
}

.workspace-mig-modal-body {
  display: flex;
  flex-direction: row;
}
.workspace-mig-modal-body h2 {
  color: #11c5d9;
}
.workspace-mig-progress {
  width: 100px;
  display: inline-block;
  padding-bottom: 6px;
}

.workspace-mig-progress-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin-right: 20px;
  justify-content: center;
}

.workspace-mig-buttons {
  display: flex;
  justify-content: left;
  margin-top: 10px;
}

.workspace-mig-message {
  font-size: 14px;
  color: #c0bdbd;
}

#invoice_viewer > canvas {
  max-width: 100%;
}

.easyinvoicemodal .rs-modal-content {
  padding: 0 4px;
}

.easyinvoicemodal .rs-modal-body {
  margin-top: 46px;
}

.confirmModal {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  padding: 10px;
}

.confirmModalIcon {
  color: #dcdfe0;
  font-size: 48px !important;
  margin-bottom: 10px;
}
.confirmTitle {
  font-weight: 600;
  font-size: 1em;
}
.confirmMessageDiv {
  display: grid;
  margin-left: 2em;
}
.confirmMsg {
  font-size: 0.9em;
  margin-top: 4px;
  color: #b8a5a5;
}

.dndscontainer {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-left-width: 0px;
  border-right-width: 0px;
  border-radius: 3px;
  outline: none;
  padding: 0;
}
.dndsitem {
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 400;
 
  justify-content: space-between;
  font-size: 12px;
}
